import request from '../utils/request'

/**
 * 发表评论
 * @param params
 */
export const publicComment = (params) => {
  return request('POST', '/playerComment/add', params, true, false)
}

/**
 * 获取评论列表
 * @param params
 */
export const commentList = (params) => {
  return request('POST', '/commonBase/list', params, true, false)
}

/**
 * 获取精选评论
 * @param params
 */
export const cherryPickCommentList = (params) => {
  return request('POST', '/commonBase/pickList', params, true, false)
}